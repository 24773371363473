import React from "react";
import { Component } from "react";
import { get, map } from "lodash";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { Alert, Table } from "reactstrap";
import { getStatsTotali, getStats } from "app/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { HA_ESM } from "common/datiPortale";

class Statistiche extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {get(this.props, "user.userType") === "Admin" ? (
          <div style={{ marginBottom: 40 }}>
            <span style={{ fontWeight: "bold", marginBottom: 5 }}>
              {t("stats_TOTALE_label")}:{" "}
            </span>

            <AsyncData loaderAction={this.props.getStatsTotali}>
              {({ loading, loadingError, data }) => {
                console.log({ loading, loadingError, data });
                if (loading) {
                  return <Loading />;
                }
                if (loadingError) {
                  return <Alert color="danger">{loadingError.message}</Alert>;
                }
                return (
                  <Table responsive={true} bordered={true} striped={true}>
                    <thead>
                      <tr>
                        <th style={{}}>
                          {t("stats_table_col_tipo_soggetto")}{" "}
                        </th>
                        <th style={{}}>
                          {t("stats_table_col_soggetti_in_reclutamento")}{" "}
                        </th>
                        <th style={{}}>
                          {t("stats_table_col_soggetti_ritirati")}{" "}
                        </th>
                        <th style={{}}>
                          {t("stats_table_col_soggetti_esclusi")}{" "}
                        </th>
                        <th style={{}}>
                          {t("stats_table_col_soggetti_completati")}{" "}
                        </th>
                        {HA_ESM && (
                          <th style={{}}>
                            {t("stats_table_col_soggetti_esm_actigrafo")}{" "}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {map(data, (row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td style={{}}>{row.userType}</td>
                          <td style={{}}>{row.creati}</td>
                          <td style={{}}>{row.ritirati}</td>
                          <td style={{}}>{row.esclusi}</td>
                          <td style={{}}>
                            {get(row, "completati")} /{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {get(row, "nonCompletati")}
                            </span>
                          </td>
                          {HA_ESM && (
                            <td style={{}}>
                              {get(row, "userType") == "Operatore" ? (
                                " "
                              ) : (
                                <>
                                  {get(row, "esm_completati")} /{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {get(row, "esm")}
                                  </span>
                                </>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                );
              }}
            </AsyncData>
          </div>
        ) : null}

        <AsyncData loaderAction={this.props.getStats}>
          {({ loading, loadingError, data }) => {
            console.log({ loading, loadingError, data });
            if (loading) {
              return <Loading />;
            }
            if (loadingError) {
              return <Alert color="danger">{loadingError.message}</Alert>;
            }
            return (
              <Table responsive={true} bordered={true} striped={true}>
                <thead>
                  <tr>
                    <th style={{}}>{t("stats_table_col_centro")} </th>
                    <th style={{}}>{t("stats_table_col_tipo_soggetto")} </th>
                    <th style={{}}>
                      {t("stats_table_col_soggetti_in_reclutamento")}{" "}
                    </th>
                    <th style={{}}>
                      {t("stats_table_col_soggetti_ritirati")}{" "}
                    </th>
                    <th style={{}}>
                      {t("stats_table_col_soggetti_completati")}{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {map(data, (row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td style={{}}>{row.codiceCentro}</td>
                      <td style={{}}>{row.userType}</td>
                      <td style={{}}>{row.creati}</td>
                      <td style={{}}>{row.ritirati}</td>
                      <td style={{}}>
                        {get(row, "completati")} /{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {get(row, "nonCompletati")}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            );
          }}
        </AsyncData>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getStatsTotali: getStatsTotali,
  getStats: getStats
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Statistiche)
);
